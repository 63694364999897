import React from "react"
import hands from "../../../assets/hands.png"
import "./index.scss"

import { connect } from "react-redux"

const InsuranceContractCards = ({ oneInsurance }) => {
  console.log("MRALEs", oneInsurance)
  return (
    <>
      {oneInsurance?.Istaknuto?.Istaknute_Kartice.map(prominentItem => (
        <div className="insurance-contract-card" key={prominentItem.id}>
          {prominentItem?.Slika ? (
            <img
              src={`https://api.trebamosiguranje.hr${prominentItem?.Slika?.url}`}
              alt="hands Image"
              className="insurance-contract-img"
              onLoad={e => e.target.classList.add("loaded")}
            />
          ) : null}
          <h4 className="insurance-contract-card-title">
            {prominentItem?.Naslov}
          </h4>
          <p className="insurance-contract-card-desc">{prominentItem.Opis}</p>
        </div>
      ))}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneInsurance: state.strapiReducer.oneInsurance,
  }
}

export default connect(mapStateToProps, null)(InsuranceContractCards)
