import React, { useEffect, useState } from "react"
import Header from "../browser/components/Header"
import InsurancePageContent from "../views/InsurancePageContent"
import Footer from "../universal/components/Footer"
import strapi from "../api/strapi"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getOneInsurance } from "../redux/actions/strapiActions"
import Loader from "../universal/components/Loader"
import Cookies from "../universal/components/Cookies"
import { showCookieBanner } from "../helpers/functions"

const InsurancePage = ({ params, oneInsurance, getOneInsurance }) => {
  const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0)
  //   })

  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 500)
  // }, [params])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const responseSpecificPageData = await strapi.get(
          `/proizvods?Slug=${params.id}`
        )

        getOneInsurance(responseSpecificPageData.data[0])
        document.title = `Dalmatiko osiguranje | ${responseSpecificPageData.data[0]?.Ime}`
        setTimeout(() => {
          setLoading(false)
        }, 500)
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData()
  }, [params.id, getOneInsurance])

  // return (
  //   <>
  //     {loading ||
  //     oneInsurance?.length === 0 ||
  //     oneInsurance === [] ||
  //     oneInsurance === undefined ? (
  //       <Loader />
  //     ) : (
  //       <>
  //         <Header />
  //         <InsurancePageContent />
  //         <Footer />
  //       </>
  //     )}
  //   </>
  // )

  if (
    oneInsurance?.length !== 0 ||
    oneInsurance !== [] ||
    oneInsurance !== undefined
  ) {
    return (
      <>
        <Header />
        <InsurancePageContent loading={loading} />
        <Footer />
        {showCookieBanner() ? <Cookies /> : null}
      </>
    )
  } else {
    return <Loader />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneInsurance: state.strapiReducer.oneInsurance,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOneInsurance: bindActionCreators(getOneInsurance, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsurancePage)
