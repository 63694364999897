import React from "react"
import PostImage from "../../universal/components/PostImage"
import PostText from "../../universal/components/PostText"
import PostTitle from "../../universal/components/PostTitle"
import ConclusionInsurance from "../../universal/components/ConclusionInsurance"
import InsuranceAdvantages from "../../universal/components/InsuranceAdvantages"
import InsuranceContract from "../../universal/components/InsuranceContract"
import PaymentInsurance from "../../universal/components/PaymentInsurance"
import "./index.scss"
import "../root_anims.scss"
import Loader from "../../universal/components/Loader"

const InsurancePageContent = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100vw",
            minHeight: "100vh",
            backgroundImage:
              "linear-gradient(to bottom, #eef5fe 0%, #ffffff 40%)",
            position: "relative",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="insurance-page-content-holder">
          <PostTitle />
          <PostImage />
          <PostText />
          <InsuranceAdvantages />
          <InsuranceContract />
          <PaymentInsurance />
          <ConclusionInsurance />
        </div>
      )}
    </>
  )
}

export default InsurancePageContent
