import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import "./index.scss"
import strapi from "../../../api/strapi"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getConclusionData,
  openModal,
} from "../../../redux/actions/strapiActions"
import { gaOfferClickEvent } from "../../../helpers/functions/googleAnalytics"

const ConclusionInsurance = ({
  conclusion,
  getConclusionData,
  openModal,
  isOpenModal,
}) => {
  let location = useLocation().pathname

  useEffect(() => {
    if (!isOpenModal) document.body.classList.remove("body-scroll")
  }, [isOpenModal])

  useEffect(() => {
    const fetchData = async () => {
      const responseConclusion = await strapi.get(
        `/conclusions?slug=${
          location === "/"
            ? "landing"
            : location.includes("insurance")
            ? "insurance"
            : "landing"
        }`
      )
      getConclusionData(responseConclusion.data[0])
    }
    fetchData()
  }, [location])

  return (
    <>
      <div className="conclusion-insurance-holder">
        <h2 className="conclusion-insurance-title">{conclusion?.Naslov}</h2>

        <p className="conclusion-insurance-desc">{conclusion?.Opis}</p>

        <div className="offer-btn-holder">
          <button
            className="offer-btn"
            onClick={() => {
              gaOfferClickEvent(location === "/"? "Klik na Zatrazi Ponudu sa pocetne stranice" : "Klik na Zatrazi Ponudu sa stranice osiguranja (pozicija B)")
              openModal()
            }}
            style={{ cursor: "pointer" }}
          >
            Zatraži ponudu
          </button>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    conclusion: state.strapiReducer.conclusion,
    isOpenModal: state.strapiReducer.isOpenModal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getConclusionData: bindActionCreators(getConclusionData, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConclusionInsurance)
