import React from "react"
import { CheckmarkIcon } from "../../../assets/icons"
import "./index.scss"

import { connect } from "react-redux"

const InsuranceAdvantages = ({ oneInsurance }) => {
  return (
    <div className="insurance-advantages-holder">
      <h2 className="insurance-advantages-title">
        {oneInsurance?.Pokrivenost?.Naslov}
      </h2>

      <p className="insurance-advantages-desc">
        {oneInsurance?.Pokrivenost?.Opis}
      </p>

      <div className="insurance-advantages-table-container">
        <h4 className="insurance-advantages-table-title">
          {oneInsurance?.Pokrivenost?.Naslov_2}
        </h4>

        <ul className="insurance-advantages-table-list">
          {oneInsurance?.Pokrivenost?.Pokrivenost.map(pokrivenost => (
            <li
              className="insurance-advantages-table-item"
              key={pokrivenost.id}
            >
              <CheckmarkIcon />
              <span className="item">{pokrivenost.Stavka}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneInsurance: state.strapiReducer.oneInsurance,
  }
}

export default connect(mapStateToProps, null)(InsuranceAdvantages)
