import React from "react"
import nfc from "../../../assets/nfc.jpg"
import dinersClub from "../../../assets/dinersClub.png"
import diners from "../../../assets/diners.png"
import visa from "../../../assets/visa.png"
import mastercard from "../../../assets/mastercard.png"
import maestro from "../../../assets/maestro.png"
import "./index.scss"

const PaymentInsurance = () => {
  return (
    <div className="payment-insurance-holder">
      <div className="payment-insurance-container">
        <h2 className="payment-insurance-title">
          Svatko si može priuštiti sigurnost
        </h2>

        <p className="payment-insurance-desc">
          Sigurnosti nikad dosta. Budi slobodan i osiguran u svakom trenutku, a plaćati možeš na tebi najdraži način: Diners, Visa, Maestro, Mastercard ili opća uplatnica.
        </p>

        <div className="card-options">
          <img src={diners} alt="Dinerc Club" className="card-imgs" />
          <img src={maestro} alt="Dinerc Club" className="card-imgs" />
          <img src={mastercard} alt="Dinerc Club" className="card-imgs" />
          <img src={visa} alt="Dinerc Club" className="card-imgs" />
        </div>

        <img
          src={nfc}
          alt="Nfc Image"
          className="payment-img"
          onLoad={e => e.target.classList.add("loaded")}
        />
      </div>
    </div>
  )
}

export default PaymentInsurance
