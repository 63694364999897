import React from "react"
import InsuranceContractCards from "../../../universal/components/InsuranceContractCards"
import "./index.scss"

const SliderInsurance = () => {
  return (
    <div className="slider-insurance">
      <div className="insurance-items">
        <InsuranceContractCards />
      </div>
    </div>
  )
}

export default SliderInsurance
