import React, { useEffect, useState } from "react"
import InsuranceContractCards from "../InsuranceContractCards"
import SliderInsurance from "../../../browserMobile/components/SliderInsurance"
import "./index.scss"

import { connect } from "react-redux"

const InsuranceContract = ({ oneInsurance }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="insurance-contract-holder">
      <div className="insurance-text1">
        <h2 className="insurance-contract-title">
          {oneInsurance?.Istaknuto?.Naslov}
        </h2>
        <p className="insurance-contract-desc1">
          {oneInsurance?.Istaknuto?.Opis}
        </p>
        {/* {windowWidth > 1024 && <span className="cut-image"></span>} */}
      </div>

      {windowWidth > 1024 ? (
        <div className="insurance-contract-card-container">
          <InsuranceContractCards />
        </div>
      ) : (
        <SliderInsurance />
      )}

      <div className="insurance-text2">
        <p className="insurance-contract-desc2">
          {oneInsurance?.Istaknuto?.Opis_2}
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    oneInsurance: state.strapiReducer.oneInsurance,
  }
}

export default connect(mapStateToProps, null)(InsuranceContract)
